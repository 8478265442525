import { useEffect } from 'react'
import { useLocation } from '@reach/router'
export default function useScrollToElementByURLHash() {
  const routeLocation = useLocation()

  useEffect(() => {
    if (routeLocation.hash) {
      setTimeout(() => {
        const elementId = routeLocation?.hash.replace('#', '')
        const element = document?.getElementById(elementId)
        const elementByClass = document?.querySelector(`.${elementId.toLowerCase()}`)
        if (element || elementByClass) {
          const elementToUse = element ? element : elementByClass
          elementToUse.scrollIntoView({
            behavior: 'smooth'
          })
        }
      }, 2400)
    }
    return () => {}
  }, [])
}
