import React from 'react'
import MinusIcon from '../assets/MinusIcon'
import PlusIcon from '../assets/PlusIcon'
import { Box } from 'theme-ui'
export default function Counter({ onMinusChange, onAddChange, value, minValue, maxValue }) {
  const buttonInActiveStyles = {
    opacity: '0.5',
    color: 'red'
  }
  return (
    <Box sx={styles.container}>
      <Box className="minusButton" onClick={onMinusChange} style={value >= minValue ? {} : buttonInActiveStyles}>
        <MinusIcon />
      </Box>
      <Box sx={styles.sizeContainer}>{value}%</Box>
      <Box className="plusButton" style={value <= maxValue ? {} : buttonInActiveStyles} onClick={onAddChange}>
        <PlusIcon />
      </Box>
    </Box>
  )
}

const styles = {
  container: {
    borderRadius: '50px',
    backgroundColor: 'light',
    display: 'flex',
    width: 'fit-content',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 8px'
  },
  '.minusButton': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    ':hover': { color: 'primary' }
  },
  '.plusButton': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    ':hover': { color: 'primary' }
  },
  sizeContainer: {
    padding: '0.5rem',
    backgroundColor: 'purple',
    borderRadius: '100px',
    boxShadow: '2px 2px 6px black',
    margin: '0rem 0.5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    fontSize: '12px',
    width: '25px'
  }
}
