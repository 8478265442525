import React from 'react'
import HideImages from './ConfigComponents/HideImages'
import FontColor from './ConfigComponents/FontColor'
import SectionTitle from './TextContent/SectionTitle'
import FontSize from './ConfigComponents/FontSize'
import LineHeight from './ConfigComponents/LineHeight'
import LetterSpacing from './ConfigComponents/LetterSpacing'
import HighlightLinks from './ConfigComponents/HighlightLinks'
import TitleBackgroundColor from './ConfigComponents/TitleBackgroundColor'
import Monochrome from './ConfigComponents/Monochrome'
import HighlightTitles from './ConfigComponents/HighlightTitles'
import TextCase from './ConfigComponents/TextCase'
import TitleColor from './ConfigComponents/TitleColor'
import FontButtons from './ConfigComponents/FontButtons'
import HighContrast from './ConfigComponents/HighContrast'
import LowContrast from './ConfigComponents/LowContrast'
import { Box } from 'theme-ui'
export default function StyleSettings() {
  return (
    <Box sx={styles.container}>
      <SectionTitle>General Text Styles</SectionTitle>
      <FontColor />
      <Box sx={styles.inlineSection}>
        <FontSize />
        <LineHeight />
        <LetterSpacing />
        <TextCase />
        <FontButtons />
      </Box>
      <SectionTitle>Title Styles</SectionTitle>
      <Box sx={styles.inlineSection}>
        <TitleColor />
        <TitleBackgroundColor />
        <HighlightTitles />
      </Box>
      <SectionTitle>Extra Setting</SectionTitle>
      <Box sx={styles.inlineSection}>
        <HighlightLinks />
        <HideImages />
        <Monochrome />
        <HighContrast />
        <LowContrast />
      </Box>
    </Box>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '5px 20px 60px'
  },
  inlineSection: {
    display: 'flex',
    flexWrap: 'wrap'
  }
}
