import React from 'react'
import Index from './components/Index'
import './App.css'

function App(props) {
  return (
    <div className="Gonation-Accessibility-App">
      <Index {...props} />
    </div>
  )
}

export default App
