import React from 'react'
import { Box } from 'theme-ui'
export default function SectionTitle({ children }) {
  return <Box sx={styles.container}>{children}</Box>
}

const styles = {
  container: {
    width: '100%',
    borderBottom: '1px solid lightgrey',
    padding: '5px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: '13px',
    color: 'text',
    margin: '0px 0px 16px'
  }
}
