import React, { useContext } from 'react'
import { store } from '../Context/Store'
import SettingsBox from './SettingsBox'
// import ColorsSelect from './ColorsSelect'
import BoldIcon from '../assets/BoldIcon'
import TextAlignLeftIcon from '../assets/TextAlignLeftIcon'
import TextAlignCenterIcon from '../assets/TextAlignCenterIcon'
import TextAlignRightIcon from '../assets/TextAlignRightIcon'
import ItalicIcon from '../assets/ItalicIcon'
import styled from 'styled-components'

import { Box, Button } from 'theme-ui'

export default function FontColor() {
  const { globalState, dispatch } = useContext(store)

  const activeButtonStyles = toggle => {
    return { border: toggle ? '1px solid' : 'none' }
  }

  return (
    <SettingsBox title={'Font Styles'}>
      <Box sx={styles.container}>
        <Button
          sx={{
            ...styles.button,
            ...activeButtonStyles(globalState.textBold)
          }}
          onClick={() => dispatch({ type: 'TOGGLE_BOLD' })}
        >
          <BoldIcon />
        </Button>
        <Button
          sx={{
            ...styles.button,
            ...activeButtonStyles(globalState.textItalic)
          }}
          onClick={() => dispatch({ type: 'TOGGLE_ITALIC' })}
        >
          <ItalicIcon />
        </Button>
        <Button
          sx={{
            ...styles.button,
            ...activeButtonStyles(globalState.textAlignment === 'left')
          }}
          onClick={() => dispatch({ type: 'SET_TEXT_ALIGNMENT', data: 'left' })}
        >
          <TextAlignLeftIcon />
        </Button>

        <Button
          sx={{
            ...styles.button,
            ...activeButtonStyles(globalState.textAlignment === 'center')
          }}
          onClick={() => dispatch({ type: 'SET_TEXT_ALIGNMENT', data: 'center' })}
        >
          <TextAlignCenterIcon />
        </Button>

        <Button
          sx={{
            ...styles.button,
            ...activeButtonStyles(globalState.textAlignment === 'right')
          }}
          onClick={() => dispatch({ type: 'SET_TEXT_ALIGNMENT', data: 'right' })}
        >
          <TextAlignRightIcon />
        </Button>
      </Box>
    </SettingsBox>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  button: {
    background: 'none',
    boxShadow: 'none',
    border: 'none',
    backgroundColor: 'transparent',
    fontSize: '22px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0.25rem 0.25rem 0.25rem',
    padding: '0.25rem',
    ':hover': {}
  }
}
