import { useEffect } from 'react'
import { forceCheck } from 'react-lazyload'

export default function useLazyLoadingChecker() {
  // check for elements in page view need and force a load if in page view
  useEffect(() => {
    setTimeout(() => {
      forceCheck()
    }, 750)
    setTimeout(() => {
      forceCheck()
    }, 1500)
    setTimeout(() => {
      forceCheck()
    }, 2000)
    setTimeout(() => {
      forceCheck()
    }, 3000)
    return () => {}
  }, [])

  return null
}
