import React, { useContext } from 'react'
import Dropdown from 'react-dropdown'
import 'react-dropdown/style.css'
import { store } from '../Context/Store'
import { Box } from 'theme-ui'
import SettingsBox from './SettingsBox'

export default function TextCase() {
  const { globalState, dispatch } = useContext(store)

  const options = ['uppercase', 'lowercase', 'capitalize', 'initial']

  return (
    <SettingsBox title={'Text Case'} style={{ zIndex: '5000' }}>
      <Box sx={styles.container}>
        <Dropdown
          options={options}
          onChange={e => dispatch({ type: 'SET_TEXT_CASE', data: e.value })}
          value={globalState.textCase}
          placeholder="Select an option"
        />
      </Box>
    </SettingsBox>
  )
}

const styles = {
  container: {
    zIndex: 5000,
    '.Dropdown-control': {
      backgroundColor: 'transparent',
      borderColor: 'primary',
      color: 'text',
      width: 'fit-content',
      fontSize: '14px',
      '.Dropdown-placeholder': { textTransform: 'capitalize !important' }
    },
    '.Dropdown-option': { textTransform: 'capitalize !important' }
  }
}
